<script lang="ts" setup>
import { MediaImageFragment } from '~/api/graphql/generated'

export interface SlideProps {
  type: string
  quote: string
  image?: MediaImageFragment
  video: string
  name: string
  position: string
}

const props = defineProps<{
  entity: SlideProps
}>()

const { controller, iframeSrc } = useModalVideo(props.entity.video)
</script>

<template>
  <div class="stories-slide" :type="entity.type">
    <div :class="['quote', 'video' === entity.type ? 'big' : 'text-medium']" v-html="entity.quote" />

    <div class="bottom">
      <div class="image">
        <Image :entity="entity.image as MediaImageFragment" type="teaser" />
      </div>

      <div v-if="entity.type !== 'video'" class="name">
        <h5>{{ entity.name }}</h5>
        <span class="text-xsmall">{{ entity.position }}</span>
      </div>

      <div v-else>
        <Button secondary icon="media" @click="controller.open">
          {{ $t('media.playButton') }}
        </Button>

        <ModalVideo :controller="controller">
          <iframe v-if="iframeSrc" :src="iframeSrc" frameborder="0" allowfullscreen />
        </ModalVideo>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stories-slide {
  --padding-inline: 24px;
  --padding-block: 30px;
  --author-image-size: 60px;
  --quote-color: var(--c-text-dark);

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  height: 100%;

  box-shadow: var(--shadow-light-bottom);
  background: var(--c-white);

  @include breakpoint(tl) {
    --padding-inline: 48px;
    --author-image-size: 80px;
  }

  &[type='text'] {
    --quote-color: var(--c-blue-100);
  }

  &[type='video'] {
    --quote-color: var(--c-white);

    .quote {
      height: auto;
      margin-top: auto;
    }

    .bottom {
      border: none;

      padding-top: 0;
      padding-bottom: 40px;

      .image {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border-radius: 0;

        z-index: 0;
        &:after {
          content: '';
          @include fill;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .quote {
    height: 100%;
    color: var(--quote-color);

    padding-top: var(--padding-block);
    padding-inline: var(--padding-inline);

    font-weight: 500;
    z-index: 1;

    &.big {
      :deep(p) {
        @include f-28;
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 9;
        overflow: hidden;
        margin-bottom: 0;
      }
    }
  }

  .bottom {
    border-top: 1px solid var(--c-line-light);
    padding-block: 18px;
    padding-inline: var(--padding-inline);

    display: flex;
    align-items: center;
    gap: 16px;

    @include breakpoint(tl) {
      padding-block: 24px;
    }

    .image {
      display: block;

      height: var(--author-image-size);
      width: var(--author-image-size);

      border-radius: 50%;

      overflow: hidden;

      & > img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }

    .name {
      h5 {
        margin-bottom: 0;
      }

      span {
        color: var(--c-text-dark);
      }
    }
  }
}
</style>
